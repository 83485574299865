import { memo, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { round } from 'lodash';

import { OddStatisticView } from 'components/betting/GamblerSettings/constants';
import {
    oddStatisticSportEventIdsSetter,
    oddStatisticVar,
} from 'layouts/BettingLayout/bettingLayoutState';
import idPathParser from 'utils/idPathParser';

interface Props {
    path: string;
    oddStatisticView: OddStatisticView;
}

const OddStatistic: React.FC<Props> = ({ path, oddStatisticView }) => {
    const { sportEventId } = idPathParser.split('Odd', path);
    const oddStatistic = useReactiveVar(oddStatisticVar)[path];

    useEffect(() => {
        if (sportEventId) {
            oddStatisticSportEventIdsSetter(sportEventId);
        }
    }, [sportEventId]);

    if (!oddStatistic) return null;

    const oddPercent = getPercentHelper(oddStatistic, oddStatisticView);

    if (!oddPercent) return null;

    return (
        <div className="shrink-0 text-green-500 typo-sm-1">{`${oddPercent}%`}</div>
    );
};

const getPercentHelper = (
    { percent, percentsSum }: Record<string, number>,
    oddStatisticView: OddStatisticView
): number | null => {
    switch (oddStatisticView) {
        case OddStatisticView.Match:
            return percent;

        case OddStatisticView.Market:
            if (!percent || !percentsSum) return null;

            return round((percent * 100) / percentsSum, 2);

        default: {
            return null;
        }
    }
};

export default memo(OddStatistic);
