import formatRFC3339 from 'date-fns/formatRFC3339';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';

import { SportEventStatus } from 'types/api.bet';
import type {
    GetOddDynamicsByIntervalVariables,
    MatchBase,
} from 'types/gql.bet';
import idPathParser from 'utils/idPathParser';
import { useGetOddDynamicsByInterval } from './getOddDynamicsByInterval.bet';

interface Input {
    oddPath: string;
    sportEvent?: MatchBase;
}

interface Output {
    dynamics: number[];
}

const useOddHistory = ({ oddPath, sportEvent }: Input): Output => {
    const { sportEventId, marketId, oddId } = idPathParser.split(
        'Odd',
        oddPath
    );

    const { data } = useGetOddDynamicsByInterval(() => ({
        variables: getVariables({
            marketId,
            sportEventId,
            oddId,
            sportEventStatus:
                sportEvent?.fixture.status || SportEventStatus.NotStarted,
        }),
        skip: !sportEvent,
    }));

    const dynamics =
        data?.oddDynamicsByInterval.dynamics.map((dynamic) => dynamic.value) ||
        [];

    return {
        dynamics,
    };
};

interface GetVariablesInput {
    marketId: string;
    sportEventId: string;
    oddId: string;
    sportEventStatus: SportEventStatus;
}

function getVariables({
    marketId,
    sportEventId,
    oddId,
    sportEventStatus,
}: GetVariablesInput): GetOddDynamicsByIntervalVariables {
    const date = new Date();

    const isHours = [
        SportEventStatus.Live,
        SportEventStatus.Suspended,
    ].includes(sportEventStatus);

    const from = formatRFC3339(isHours ? subHours(date, 2) : subDays(date, 3));

    return {
        marketId,
        sportEventId,
        oddId,
        pointCount: 5,
        from,
    };
}

export default useOddHistory;
