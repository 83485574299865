import { FC, memo } from 'react';
import cn from 'classnames';

import BinaryStorageImage from 'components/BinaryStorageImage';
import PackIcon, { IconsPack } from 'components/PackIcon';
import SmartLink from 'components/SmartLink';
import { CompetitorType } from 'types/gql.bet';

const serverIndicator = cn(
    'relative before:absolute before:bottom-[2px] before:right-0 before:z-10 before:h-[10px] before:w-[10px] before:rounded-full before:border-2 before:border-solid before:border-surface-light before:bg-primary-white before:content-[""]  '
);

const hoverClassName = cn(
    'rounded-[8px] bg-transparent p-2 transition-colors duration-300 ease-in-out hover:bg-primary-white/10'
);

const ellipsis = cn('w-full truncate');

const CompetitorDisplayType = {
    Row: 'row',
    Column: 'column',
} as const;

type K = keyof typeof CompetitorDisplayType;

export enum Sizes {
    Default = 'Default',
    Small = 'Small',
}

const LineClamps = {
    '2': 'line-clamp-2',
    '3': 'line-clamp-3',
} as const;

type ClassesBySize = Record<Sizes, string>;

const iconContainerClasses: ClassesBySize = {
    [Sizes.Default]: cn('h-10 w-10'),
    [Sizes.Small]: cn('h-8 w-8 md:h-10 md:w-10'),
};

const teamIconClassName: ClassesBySize = {
    [Sizes.Default]: cn(
        'flex max-h-[28px] min-h-[28px] min-w-[28px] max-w-[28px]'
    ),
    [Sizes.Small]: cn('flex h-6 w-6 md:h-[28px] md:w-[28px]'),
};

const nameClassName: ClassesBySize = {
    [Sizes.Default]: cn('typo-sm-1'),
    [Sizes.Small]: cn('typo-xs-1 md:typo-sm-1'),
};

export interface Props {
    logo: string;
    name: string;
    sportId?: string;
    isServer?: boolean;
    displayType?: (typeof CompetitorDisplayType)[K];
    className?: string;
    type: CompetitorType;
    href?: string;
    size?: Sizes;
    maxLinesForName?: 2 | 3;
}

const Competitor: FC<Props> = ({
    logo,
    name,
    sportId,
    displayType,
    className,
    isServer,
    type,
    href,
    size = Sizes.Default,
    maxLinesForName,
}) => {
    const isDisplayTypeColumn = displayType === CompetitorDisplayType.Column;

    const iconContainerCn = cn(
        'flex items-center justify-center overflow-hidden rounded-full',
        {
            'bg-surface-light': !sportId,
            'bg-surface-white-light/[.3]': sportId,
        },
        iconContainerClasses[size],
        !logo && 'p-1'
    );

    const textCn = maxLinesForName
        ? `w-full ${LineClamps[maxLinesForName]}`
        : ellipsis;

    const competitorBody = (
        <div
            className={cn(
                `flex w-full min-w-0 items-center overflow-hidden`,
                className,
                {
                    'flex-col text-center sm:flex-row sm:text-start':
                        !displayType,
                    'grow flex-col gap-0 text-center': isDisplayTypeColumn,
                    'first:mr-2 last:ml-2': isDisplayTypeColumn && !href,
                    'flex-row text-start':
                        displayType === CompetitorDisplayType.Row,
                }
            )}
            data-test="competitor"
        >
            <div className={isServer ? serverIndicator : undefined}>
                <div className={iconContainerCn}>
                    {logo ? (
                        <div
                            className={cn(
                                type === CompetitorType.Team
                                    ? teamIconClassName[size]
                                    : 'w-full'
                            )}
                        >
                            <BinaryStorageImage
                                width={40}
                                height={40}
                                src={logo}
                            />
                        </div>
                    ) : (
                        <PackIcon
                            id="default-logo"
                            pack={IconsPack.SpriteIcons}
                            className={teamIconClassName[size]}
                        />
                    )}
                </div>
            </div>

            <span
                className={cn(
                    'ml-2 text-primary-white typo-sm-1',
                    textCn,
                    nameClassName[size],
                    {
                        'mt-2': sportId,
                    }
                )}
                data-test="competitor-title"
            >
                {name}
            </span>
        </div>
    );

    if (href) {
        return (
            <div className="flex w-full min-w-0 items-center justify-center first:mr-2 last:ml-2">
                <SmartLink
                    href={href}
                    className={cn('overflow-hidden', hoverClassName)}
                >
                    {competitorBody}
                </SmartLink>
            </div>
        );
    }

    return competitorBody;
};

export default memo(Competitor);
