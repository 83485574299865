import { reduce } from 'lodash';

export type Line = {
    x1: number;
    x2: number;
    y1: number;
    y2: number;
    gradient: string;
};

export type CirclePosition = { x: number; y: number; color: string };

type Config = {
    width: number;
    padding: number;
    border: number;
    maxHeight: number;
};

export type Acc = {
    points: Line[];
    circlePosition: CirclePosition[];
};

interface Output {
    height: number;
    colors: Record<string, string>;
    result: Acc;
}

const colors = {
    first: '#604bce',
    up: '#00acc0',
    down: '#aa0000',
    startDown: 'url(#down)',
    firstDown: 'url(#first_down)',
    firstUp: 'url(#first_up)',
    startUp: 'url(#up)',
};
const down = [colors.startDown, colors.firstDown, colors.down];
const prevDownFn = ({ points }: Acc, i: number): boolean => {
    const currentGradient = points[i - 1]?.gradient;

    return !!currentGradient && down.includes(currentGradient);
};

const gradientFn = (
    curr: number,
    i: number,
    array: number[],
    prevDown: boolean
): string => {
    const nextItem = array[i + 1];
    const prevItem = array[i - 1];

    /* eslint-disable no-nested-ternary */
    return i === 0 && curr <= nextItem
        ? colors.firstUp
        : i === 0 && curr > nextItem
          ? colors.firstDown
          : prevDown && curr > nextItem
            ? colors.down
            : curr > nextItem && !prevDown
              ? colors.startDown
              : curr < prevItem && !(curr > nextItem)
                ? colors.startUp
                : colors.up;
};

export const drawChart = (data: number[], config: Config): Output => {
    const { width, border, padding, maxHeight } = config; // h - max height of svg
    const circleRadius = border * 2;
    const paddedWidth = width - circleRadius; // with padding inside svg
    const widthOfLine = (paddedWidth - padding) / (data.length - 1); // width of line
    const min = Math.min(...data);
    const diff = Math.max(...data) - min || 1;
    const coefficient = (maxHeight - padding) / diff;
    const paddedHeight = maxHeight - padding; // dynamic height for centered by vertically

    const result: Acc = reduce(
        data,
        (acc: Acc, curr: number, i: number, array: number[]) => {
            const nextIndex = i + 1;
            const x1 = i * widthOfLine + circleRadius; // start line by x
            const y1 = paddedHeight - coefficient * (curr - min) + circleRadius; // start line by y
            const x2 = data[nextIndex]
                ? nextIndex * widthOfLine + circleRadius
                : x1; // end line by x
            const y2prev =
                paddedHeight -
                coefficient * ((array[nextIndex] || curr) - min) +
                circleRadius; // end line by y
            const y2 = y1 === y2prev ? y2prev + 0.0001 : y2prev; // fixed display of SVG line for chromium
            const isPrevDown = prevDownFn(acc, i);
            const gradient = gradientFn(curr, i, array, isPrevDown);
            const downOrUpColors = isPrevDown ? colors.down : colors.up;
            const clColor = i === 0 ? colors.first : downOrUpColors;

            return {
                points: [...acc.points, { x1, y1, x2, y2, gradient }],
                circlePosition: [
                    ...acc.circlePosition,
                    { x: x1, y: y1, color: clColor },
                ],
            };
        },
        { points: [], circlePosition: [] }
    );

    return { height: maxHeight, result: { ...result }, colors };
};
