import { values } from 'lodash';

import { AppLink, ScoreTypes } from 'app-constants';
import { OddStatus, SportEventType } from 'types/api.bet';

export enum SpecifierName {
    total = 'total',
    hcp = 'hcp',
    periodnr = 'periodnr',
    overtimenr = 'overtimenr',
    halfnr = 'halfnr',
    inningnr = 'inningnr',
    quarternr = 'quarternr',
    setnr = 'setnr',
    gamenr = 'gamenr',
    mapnr = 'mapnr',
    xth = 'xth',
    roundnr = 'roundnr',
    pointnr = 'pointnr',
    score = 'score',
    top = 'top',
    goalnr = 'goalnr',
    from = 'from',
    to = 'to',
    minute = 'minute',
    competitornumber = 'competitornumber',
    winners = 'winners',
    killnr = 'killnr',
    variant = 'variant',
}

export const RESULTED_ODD_STATUSES = [
    OddStatus.Win,
    OddStatus.HalfWin,
    OddStatus.Loss,
    OddStatus.HalfLoss,
    OddStatus.Refunded,
] as const;

export const SPECIFIER_VALUE_SCORE_TYPES_MAPPING = {
    1: ScoreTypes.TYPE_PERIOD_1ST_HALF,
    2: ScoreTypes.TYPE_PERIOD_2ND_HALF,
};

export const SCORE_TYPES_WITHOUT_CHECK_ON_SPEC_VALUE = values(
    SPECIFIER_VALUE_SCORE_TYPES_MAPPING
);

export const SPECIFIER_SCORE_TYPES_MAPPING = {
    [SpecifierName.inningnr]: ScoreTypes.TYPE_INNING_POINTS,
    [SpecifierName.quarternr]: ScoreTypes.TYPE_QUARTER,
    [SpecifierName.setnr]: ScoreTypes.TYPE_SET,
    [SpecifierName.gamenr]: ScoreTypes.TYPE_GAME_POINTS,
    [SpecifierName.mapnr]: ScoreTypes.TYPE_MAP,
    [SpecifierName.periodnr]: ScoreTypes.TYPE_PERIOD_REGULAR,
};

export const SPORT_EVENT_LINK = {
    [SportEventType.Match]: AppLink.SPORTS_MATCH_SLUG,
    [SportEventType.Outright]: AppLink.SPORTS_OUTRIGHT_SLUG,
};

export const ESPORT_EVENT_LINK = {
    [SportEventType.Match]: AppLink.ESPORTS_MATCH_SLUG,
    [SportEventType.Outright]: AppLink.ESPORTS_OUTRIGHT_SLUG,
};

export const ScoreTypesNumerable = [
    ScoreTypes.TYPE_SET,
    ScoreTypes.TYPE_QUARTER,
    ScoreTypes.TYPE_QUARTER_OVERTIME,
    ScoreTypes.TYPE_PERIOD_REGULAR,
    ScoreTypes.TYPE_PERIOD_OVERTIME,
    ScoreTypes.TYPE_PERIOD_BULLETS,
    ScoreTypes.TYPE_GAME_KILLS,
    ScoreTypes.TYPE_GAME_PLAYERS,
    ScoreTypes.TYPE_MAP,
    ScoreTypes.TYPE_ROUND_OVERTIME,
    ScoreTypes.TYPE_INNING_POINTS,
    ScoreTypes.TYPE_INNING_OUTS,
];
