import { useReactiveVar } from '@apollo/client';
import type { PartialMatchMarket } from 'types';

import { ID_PATH_DEVIDER } from 'app-constants';
import { betslipVars } from 'components/betting/Betslip';
import { withClientRender } from 'hocs';
import { Odd } from 'types/gql.bet';
import OddButton from './OddButton';
import OddFallback from './OddFallback';
import { Color, Size } from './types';

interface Props {
    odd: Odd;
    classNames?: string;
    market: PartialMatchMarket;
    oddsCount?: number;
    gap?: 1 | 2 | '1' | '2';
    color?: Color;
    size?: Size;
    isResizable?: boolean;
}

const betTipsKey = 'BetTips' as const;

// NOTE: need to avoid OddButton re-renders after changing betslipVars
function OddButtonWrapper({
    odd,
    classNames,
    market,
    gap,
    color = Color.Dark,
    size = Size.Default,
    oddsCount,
    isResizable,
}: Props) {
    const { oddIds: betslipOddIds, status: betslipStatus } =
        useReactiveVar(betslipVars);

    // NOTE: we need to replace the path if we got BetTipOdd it has a different path from Odd
    const path = odd.path.includes(betTipsKey)
        ? odd.path.replace(`${betTipsKey}${ID_PATH_DEVIDER}`, '')
        : odd.path;

    const isSelected = betslipOddIds.includes(path);

    return (
        <OddButton
            odd={odd}
            market={market}
            classNames={classNames}
            color={color}
            gap={gap}
            size={size}
            oddsCount={oddsCount}
            isResizable={isResizable}
            betslipStatus={betslipStatus}
            isSelected={isSelected}
            path={path}
        />
    );
}

OddButtonWrapper.Size = Size;
OddButtonWrapper.Color = Color;

export default withClientRender(OddButtonWrapper, () => <OddFallback />);
