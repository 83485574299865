import { makeVar } from '@apollo/client';
import { getCookie } from 'cookies-next';

import { CookiesType } from 'app-constants';

export const isHideTopPromoTournamentVar = makeVar<boolean>(
    Boolean(getCookie(CookiesType.HidePromoTournamentBanner))
);

export const hasTopPromoTournamentTransitionVar = makeVar<boolean>(false);

export const oddStatisticSportEventIdsVar = makeVar<Array<string>>([]);
export const oddStatisticVar = makeVar<Record<string, Record<string, number>>>(
    {}
);

export const oddStatisticSportEventIdsSetter = (sportEventId: string): void => {
    oddStatisticSportEventIdsVar(
        Array.from(new Set([...oddStatisticSportEventIdsVar(), sportEventId]))
    );
};
