import { MouseEventHandler, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { includes } from 'lodash';
import { useTranslations } from 'next-intl';
import type { PartialMatchMarket } from 'types';

import {
    AppLink,
    CompareStatus,
    CustomAppLink,
    ModalsHash,
} from 'app-constants';
import { BetslipStatus, changeBetslipOddIds } from 'components/betting/Betslip';
import { OddStatisticView } from 'components/betting/GamblerSettings/constants';
import { gamblerSettingsVar } from 'components/betting/GamblerSettings/gamblerState';
import { useRouter, useRouterPush } from 'components/HybridRouter';
import useModal from 'components/modals/useModal';
import { useAuthorization, useCheckOddIsActive, useCompare } from 'hooks';
import { useKycCheck } from 'layouts/BettingLayout/KycCheckProvider';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import type { Odd } from 'types/gql.bet';
import { MatchBase, OddStatus } from 'types/gql.bet';
import { formatDecimalOdd, idPathParser } from 'utils';
import { GlobalTranslate } from '../../../translations/types';
import { getTitle } from './utils';

interface Input {
    odd: Odd;
    market: PartialMatchMarket;
    betslipStatus: BetslipStatus;
    path: string;
    isSelected: boolean;
}

interface Output {
    titleAttr: string;
    title: string;
    resultTitle?: string;
    value: number | string;
    isActive: boolean;
    isWin: boolean;
    isLoss: boolean;
    isMobile: boolean;
    isTablet: boolean;
    showOddsStatistics: boolean;
    oddStatisticView: OddStatisticView;
    compareStatus: CompareStatus;
    handleOddClick: MouseEventHandler<HTMLDivElement>;
    sportEvent?: MatchBase;
}

const ODD_WIN_STATUSES: OddStatus[] = [OddStatus.Win, OddStatus.HalfWin];
const ODD_LOSS_STATUSES: OddStatus[] = [OddStatus.Loss, OddStatus.HalfLoss];

export default function useOddButton({
    odd,
    market,
    betslipStatus,
    path,
    isSelected,
}: Input): Output {
    const tGlobal = useTranslations('betting-global.global');
    const tSpecifier = useTranslations('betting-specifiers.specifiers');
    const { until } = useResponsive();
    const isMobile = until('sm');
    const isTablet = until('md');

    const { handleKycCheck } = useKycCheck();

    const { asPath, asPathObj } = useRouter();
    const { push } = useRouterPush();

    const { isAuthorized } = useAuthorization();

    const { openModal, closeModal } = useModal();

    const { oddFormat, showOddsStatistics, oddStatisticView } =
        useReactiveVar(gamblerSettingsVar);

    const { name: oddName, value, status } = odd;

    const { sportEventId } = path
        ? idPathParser.split('Odd', path)
        : { sportEventId: '' };

    const { isActiveOdd, sportEvent } = useCheckOddIsActive({
        odd,
        market,
        sportEventId,
    });

    const isWin = includes(ODD_WIN_STATUSES, status);
    const isLoss = includes(ODD_LOSS_STATUSES, status);
    const isNewsPage = asPath.includes(CustomAppLink.NEWS);

    const title = getTitle({
        market,
        odd,
        tSpecifier,
        competitors: sportEvent?.fixture.competitors || [],
    });
    const oddValue = Number(value);

    const { compareStatus } = useCompare({ value: oddValue });
    const handleClick = useCallback(() => changeBetslipOddIds(path), [path]);

    const handleOddClick: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isActiveOdd) return;

        if (asPathObj?.hash) {
            closeModal();
        }

        if (!isAuthorized && !isSelected) {
            openModal({ hash: ModalsHash.Authorization });
        }

        handleClick();

        if (!path || betslipStatus === BetslipStatus.Processing || isSelected)
            return;

        if (isNewsPage) {
            push(AppLink.BETS);
        }

        handleKycCheck();
    };

    const resultTitle = getResultTitle(isWin, isLoss, tGlobal);

    return {
        titleAttr: oddName,
        title,
        compareStatus,
        value: formatDecimalOdd(oddValue, oddFormat),
        resultTitle,
        isActive: isActiveOdd,
        isWin,
        isLoss,
        isMobile,
        isTablet,
        handleOddClick,
        showOddsStatistics: showOddsStatistics || false,
        oddStatisticView: oddStatisticView || OddStatisticView.Market,
        sportEvent,
    };
}

const getResultTitle = (
    isWin: boolean,
    isLoss: boolean,
    tGlobal: GlobalTranslate
): string | undefined => {
    if (isWin) return tGlobal('win');

    if (isLoss) return tGlobal('odd.Lost');
};
