import { FC, memo } from 'react';
import Image from 'next/image';

interface Props {
    src: string;
    width: number;
    height: number;
    className?: string;
}

const BinaryStorageImage: FC<Props> = ({ src, width, height, className }) => {
    return (
        <Image
            src={binaryStorageLoader({ height, src, width })}
            width={width}
            height={height}
            alt=""
            loading="lazy"
            className={className}
            style={{ objectFit: 'contain' }}
            unoptimized={true}
        />
    );
};

export const binaryStorageLoader = ({ src, width, height }: Props): string => {
    const convertedWidth = width < 64 ? width * 2 : width;
    const convertedHeight = height < 64 ? height * 2 : height;

    return `//${src.replace(
        /[^/]*$/,
        (match) => `thumb${convertedWidth}x${convertedHeight}_${match}`
    )}`;
};

export default memo(BinaryStorageImage);
