import { FC, memo } from 'react';

const OddFallback: FC = () => {
    return (
        <div className="flex min-w-0 flex-1 cursor-not-allowed flex-col rounded-default bg-surface-light p-2  not-last:mr-2 sm:h-0 sm:not-last:mb-2 sm:not-last:mr-0">
            <div className="relative flex h-4 w-full grow items-center justify-between not-last:mr-1">
                <div className="truncate text-primary-white typo-sm-2" />
            </div>
            <div className="relative flex h-4 w-full grow items-center justify-between">
                <div className="mr-1 shrink-0 text-grey-500 typo-sm-1">-</div>
            </div>
        </div>
    );
};

export default memo(OddFallback);
