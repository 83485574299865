import { SpecifierName } from 'components/betting/BetsHistory/BetItem/Odd/constants';
import { Sports } from '../Categorizer/constants';

export enum MarketTitles {
    Draw = 'Draw',
    Or = 'Or',
    None = 'None',
}

export enum MarketTag {
    EnumerableOddCompetitor = 'enumerable-odd-competitor',
    Group = 'group:',
    Main = 'main:',
    Divider = ',',
    Score = 'score',
}

export enum ScoreTypes {
    TYPE_TOTAL = 'total',
    TYPE_GAME_POINTS = 'game_points',
    TYPE_SET = 'set',
    TYPE_TIE_BREAK = 'tie_break',
    TYPE_GOLDEN_SET = 'golden_set',
    TYPE_YELLOW_CARD = 'yellow_card',
    TYPE_RED_CARD = 'red_card',
    TYPE_YELLOW_RED_CARD = 'yellow_red_card',
    TYPE_PERIOD_1ST_HALF = 'period_1st_half',
    TYPE_PERIOD_1ST_EXTRA = 'period_1st_extra',
    TYPE_PERIOD_2ND_HALF = 'period_2nd_half',
    TYPE_PERIOD_2ND_EXTRA = 'period_2nd_extra',
    TYPE_PERIOD_PENALTIES = 'period_penalties',
    TYPE_QUARTER = 'quarter',
    TYPE_QUARTER_OVERTIME = 'quarter_overtime',
    TYPE_PERIOD_REGULAR = 'period_regular',
    TYPE_PERIOD_OVERTIME = 'period_overtime',
    TYPE_PERIOD_BULLETS = 'period_bullets',
    TYPE_GAME_KILLS = 'game_kills',
    TYPE_GAME_PLAYERS = 'game_players',
    TYPE_MAP = 'map',
    TYPE_ROUND_OVERTIME = 'round_overtime',
    TYPE_INNING_POINTS = 'inning_points',
    TYPE_INNING_OUTS = 'inning_outs',
}

export const ScoreTypesNumerable = [
    ScoreTypes.TYPE_SET,
    ScoreTypes.TYPE_QUARTER,
    ScoreTypes.TYPE_QUARTER_OVERTIME,
    ScoreTypes.TYPE_PERIOD_REGULAR,
    ScoreTypes.TYPE_PERIOD_OVERTIME,
    ScoreTypes.TYPE_PERIOD_BULLETS,
    ScoreTypes.TYPE_GAME_KILLS,
    ScoreTypes.TYPE_GAME_PLAYERS,
    ScoreTypes.TYPE_MAP,
    ScoreTypes.TYPE_ROUND_OVERTIME,
    ScoreTypes.TYPE_INNING_POINTS,
    ScoreTypes.TYPE_INNING_OUTS,
];

export enum ScoreDivider {
    DEFAULT = ':',
    SET = '-',
}

export const GROUP_WITHOUT_HEADER = 'group-without-header' as const;
export const MAX_COLUMNS = 3 as const;
export const MARKET_TITLE_DIVIDER = ' - ' as const;

export const TableSpecifiers = [
    SpecifierName.total,
    SpecifierName.hcp,
    SpecifierName.xth,
    SpecifierName.roundnr,
    SpecifierName.pointnr,
    SpecifierName.score,
    SpecifierName.top,
    SpecifierName.goalnr,
    SpecifierName.overtimenr,
    SpecifierName.gamenr,
    SpecifierName.minute,
] as const;

export const TagGroupSpecifiers = [
    SpecifierName.mapnr,
    SpecifierName.setnr,
    SpecifierName.periodnr,
    SpecifierName.gamenr,
    SpecifierName.halfnr,
    SpecifierName.minute,
    SpecifierName.quarternr,
    SpecifierName.roundnr,
    SpecifierName.variant,
    SpecifierName.competitornumber,
    SpecifierName.top,
] as const;

export const drawMsgsMapping: Record<MarketTitles, string> = {
    [MarketTitles.Draw]: 'Draw',
    [MarketTitles.None]: 'None',
    [MarketTitles.Or]: 'Or',
};

export enum OutcomeTemplate {
    Competitor = `$competitor`,
}

export const SPORTS_WITHOUT_TOTAL_SCORE = [
    Sports.ESPORTS_FORTNITE,
    Sports.ESPORTS_CALL_OF_DUTY_WARZONE,
];

export const EXCLUDED_SPEC_NAMES_FROM_SUBTITLE = [
    SpecifierName.variant,
    SpecifierName.competitornumber,
];

export const MARKET_TABLE_LIMIT = 10;
