import type { Ref } from 'react';
import { memo, useRef } from 'react';
import cn from 'classnames';
import type { PartialMatchMarket } from 'types';

import { CompareStatus } from 'app-constants';
import { BetslipStatus } from 'components/betting/Betslip';
import PackIcon, { IconsPack } from 'components/PackIcon';
import { Action, useAnalyticAttrs } from 'services/GoogleAnalytics';
import type { Odd } from 'types/gql.bet';
import OddButtonHistoryWrapper from '../OddButtonWithOddHistory';
import {
    colorsContainerCn,
    colorsContainerSelectedCn,
    colorsIsActiveCn,
    colorsResultCn,
    colorsSelectedStateTextCn,
    gapCn,
    MAX_COUNT_ODD_BUTTONS,
    sizesResultCn,
    sizesTitleCn,
} from './constants';
import OddStatistic from './OddStatistic';
import { Color, Size } from './types';
import useOddButton from './useOddButton';

interface Props {
    odd: Odd;
    classNames?: string;
    market: PartialMatchMarket;
    oddsCount?: number;
    gap?: 1 | 2 | '1' | '2';
    color?: Color;
    size?: Size;
    isResizable?: boolean;
    betslipStatus: BetslipStatus;
    path: string;
    isSelected: boolean;
}

function OddButton({
    odd,
    classNames,
    market,
    gap,
    color = Color.Dark,
    size = Size.Default,
    oddsCount,
    isResizable,
    betslipStatus,
    path,
    isSelected,
}: Props): JSX.Element {
    const analytic = useAnalyticAttrs({
        defaultAttrs: {
            'data-action': Action.SelectOdd,
            'data-label': odd?.path,
        },
    });

    const innerRef = useRef<HTMLDivElement>(null);
    const {
        title,
        compareStatus,
        value,
        resultTitle,
        isActive,
        isWin,
        isLoss,
        isMobile,
        isTablet,
        titleAttr,
        handleOddClick,
        showOddsStatistics,
        oddStatisticView,
        sportEvent,
    } = useOddButton({
        odd,
        market,
        betslipStatus,
        path,
        isSelected,
    });

    const isFlattenedView =
        oddsCount === MAX_COUNT_ODD_BUTTONS && !isMobile && isResizable;
    const classIsResizableCn = cn(
        'flex min-w-0 flex-1 cursor-not-allowed rounded-default p-2',
        { 'sm:h-0 sm:not-last:mr-0': isResizable }
    );

    const { component: CoefEl } = isActive
        ? { component: value }
        : { component: '-' };

    const ResultEl = (
        <div
            className={cn(
                'mr-1 shrink-0',
                sizesResultCn[size],
                colorsResultCn[color],
                {
                    [colorsSelectedStateTextCn[color]]: isSelected,
                    '!text-grey-500': !isActive,
                }
            )}
            data-test="odd-button__result"
        >
            {resultTitle || CoefEl}
        </div>
    );
    const TitleEl = (
        <div
            className={cn(
                'truncate capitalize text-primary-white',
                sizesTitleCn[size],
                {
                    [colorsSelectedStateTextCn[color]]: isSelected,
                    'mr-1': isFlattenedView,
                }
            )}
            data-test="odd-button__title"
        >
            {title}
        </div>
    );

    const StatusEl = (
        <div className="ml-auto">
            {compareStatus !== CompareStatus.Equal ? (
                <PackIcon
                    id="branch-more-up"
                    className={cn('animate-pulse', {
                        'fill-green-500':
                            compareStatus === CompareStatus.Greater,
                        'rotate-180 fill-red-700':
                            compareStatus === CompareStatus.Smaller,
                    })}
                    pack={IconsPack.SpriteIcons}
                />
            ) : (
                showOddsStatistics && (
                    <OddStatistic
                        path={path}
                        oddStatisticView={oddStatisticView}
                    />
                )
            )}
        </div>
    );

    const renderFn = (
        ref?: Ref<HTMLDivElement>,
        onMouseEnter?: VoidFunction,
        onMouseLeave?: VoidFunction
    ): JSX.Element => (
        <div
            ref={ref}
            title={titleAttr}
            className={cn(
                classIsResizableCn,
                colorsContainerCn[color],
                classNames,
                gap ? gapCn[gap] : '',
                {
                    [colorsIsActiveCn[color]]: !isActive,
                    [colorsContainerSelectedCn[color]]: isSelected,
                    '!bg-green-900 hover:!bg-green-900': isWin,
                    '!bg-grey-900 hover:!bg-grey-900': isLoss,
                    'bg-surface-light hover:!bg-surface-light': !isActive,
                    '!cursor-pointer': isActive,
                    'flex-col': !isFlattenedView,
                }
            )}
            onClick={handleOddClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            data-category={analytic['data-category']}
            data-action={analytic['data-action']}
            data-label={analytic['data-label']}
            data-test={`odd-button ${isWin ? 'odd-button--is-win' : ''} ${
                isLoss ? 'odd-button--is-loss' : ''
            } ${isActive ? 'odd-button--is-active' : ''}`}
        >
            {isFlattenedView ? (
                <div className="relative flex h-full w-full items-center justify-items-start">
                    {TitleEl}
                    {ResultEl}
                    {isActive && StatusEl}
                </div>
            ) : (
                <>
                    <div className="relative flex h-4 w-full grow items-center justify-between not-last:mr-1">
                        {TitleEl}
                    </div>
                    <div className="relative flex h-4 w-full grow items-center justify-between">
                        {ResultEl}
                        {isActive && StatusEl}
                    </div>
                </>
            )}
        </div>
    );

    return isTablet ? (
        renderFn(innerRef)
    ) : (
        <OddButtonHistoryWrapper
            innerRef={innerRef}
            odd={odd}
            marketName={market.name}
            isActiveOdd={isActive}
            sportEvent={sportEvent}
            placement={isResizable ? 'auto' : 'top'}
        >
            {renderFn}
        </OddButtonHistoryWrapper>
    );
}

export default memo(OddButton);
