import cn from 'classnames';

import { Color, Size } from './types';

const MAX_COUNT_ODD_BUTTONS = 3 as const;

const sizesResultCn = {
    [Size.Default]: cn('typo-sm-1'),
    [Size.Small]: cn('typo-xs-1 md:typo-sm-1'),
};

const colorsResultCn = {
    [Color.Dark]: cn('text-violet-300'),
    [Color.Light]: cn('text-primary-white'),
};

const sizesTitleCn = {
    [Size.Default]: cn('typo-sm-2'),
    [Size.Small]: cn('typo-xs-2 md:typo-sm-2'),
};

const colorsContainerCn = {
    [Color.Dark]: cn('bg-surface-light hover:bg-surface-dark'),
    [Color.Light]: cn('bg-primary-white/[0.3]'),
};
const colorsContainerSelectedCn = {
    [Color.Dark]: cn('bg-violet-500 hover:bg-violet-700'),
    [Color.Light]: cn('!bg-primary-white/[0.6]'),
};

const colorsSelectedStateTextCn = {
    [Color.Dark]: cn('!text-primary-white'),
    [Color.Light]: cn('text-surface-middle'),
};

const colorsIsActiveCn = {
    [Color.Dark]: cn('hover:!bg-primary-white/[0.3]'),
    [Color.Light]: cn('text-primary-white'),
};

const gapCn: Record<string, string> = {
    1: cn('not-last:mr-1'),
    2: cn('not-last:mr-2'),
};

export {
    colorsContainerCn,
    colorsContainerSelectedCn,
    colorsIsActiveCn,
    colorsResultCn,
    colorsSelectedStateTextCn,
    gapCn,
    MAX_COUNT_ODD_BUTTONS,
    sizesResultCn,
    sizesTitleCn,
};
