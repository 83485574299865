import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';

const CHART_NODE_ID = 'chart';

interface Output {
    onMouseEnter: VoidFunction;
    onMouseLeave: VoidFunction;
    isOddHistoryOpen: boolean;
    refChart: RefObject<HTMLElement | null>;
}

const TIMEOUT = 600 as const;

const useOddButtonWithOddHistory = (): Output => {
    const [isOddHistoryOpen, setIsOddHistoryOpen] = useState<boolean>(false);
    const refChart = useRef<HTMLElement | null>(null);

    const timer = useRef<number>(0);

    const onMouseEnter = useCallback(() => {
        throttle(() => {
            clearTimeout(timer.current);

            timer.current = window.setTimeout(() => {
                setIsOddHistoryOpen(true);
            }, TIMEOUT);
        }, 100)();
    }, []);

    const onMouseLeave = useCallback(() => {
        throttle(() => {
            clearTimeout(timer.current);
            setIsOddHistoryOpen(false);
        }, TIMEOUT)();
    }, []);

    useEffect(() => () => clearTimeout(timer.current), []);

    useEffect(() => {
        const chartElement = document.getElementById(CHART_NODE_ID);

        if (chartElement) refChart.current = chartElement;
    }, []);

    return {
        isOddHistoryOpen,
        onMouseEnter,
        onMouseLeave,
        refChart,
    };
};

export default useOddButtonWithOddHistory;
