import { find } from 'lodash';
import { SpecifiersTranslate } from 'translations/types';

import { SpecifierName } from 'components/betting/BetsHistory/BetItem/Odd/constants';
import { PartialMatchMarket } from 'types';
import type { MatchBase, Odd } from 'types/gql.bet';
import { CompetitorHomeAway } from 'types/gql.bet';

interface Input {
    market: PartialMatchMarket | null;
    odd: Odd;
    tSpecifier: SpecifiersTranslate;
    competitors: MatchBase['fixture']['competitors'];
}

export const getTitle = ({
    market,
    odd: { name, id: currOddId, competitorIds },
    tSpecifier,
    competitors,
}: Input): string => {
    if (!market) return name;

    const { specifiers, odds = [] } = market;

    const hcpSpecifier = find(specifiers, (s) => s.name === SpecifierName.hcp);
    const topSpecifier = find(specifiers, (s) => s.name === SpecifierName.top);

    if (hcpSpecifier) {
        const competitor = competitors.find(({ id }) =>
            competitorIds.includes(id)
        );

        const isHome = competitor
            ? competitor.homeAway === CompetitorHomeAway.Home
            : odds[0].id === currOddId;

        const { value: valueStr } = hcpSpecifier;

        const value = isHome ? +valueStr : -+valueStr;

        const symbol = value > 0 ? '+' : '';

        if (isNaN(value)) {
            return valueStr;
        }

        return `${symbol}${value}`;
    }

    if (topSpecifier) {
        const { value } = topSpecifier;
        const descriptiveName = name.replace(value, '').trim();
        const top = tSpecifier('top');

        return `${descriptiveName}, ${top} ${value}`;
    }

    return name;
};
