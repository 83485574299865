import { FC, memo, Ref } from 'react';
import { createPortal } from 'react-dom';
import { Manager, Popper, PopperChildrenProps, Reference } from 'react-popper';
import { useReactiveVar } from '@apollo/client';

import { OddFormat } from 'app-constants';
import { gamblerSettingsVar } from 'components/betting/GamblerSettings/gamblerState';
import { MatchBase, Odd } from 'types/gql.bet';
import OddHistory from '../OddHistory';
import useOddButtonWithOddHistory from './useOddButtonWithOddHistory';

interface Props {
    children: (
        ref?: Ref<HTMLDivElement>,
        onMouseEnter?: VoidFunction,
        onMouseLeave?: VoidFunction
    ) => JSX.Element;
    odd: Pick<Odd, 'path' | 'name'>;
    marketName: string;
    innerRef: Ref<HTMLDivElement>;
    sportEvent?: MatchBase;
    placement: PopperChildrenProps['placement'];
}

interface PropsWrapper extends Props {
    isActiveOdd: boolean;
}

interface PropsOddButtonWithOddHistory extends Props {
    oddFormat: OddFormat;
}

const OddButtonWithOddHistory: FC<PropsOddButtonWithOddHistory> = ({
    children,
    innerRef,
    odd,
    marketName,
    oddFormat,
    sportEvent,
    placement,
}) => {
    const { isOddHistoryOpen, onMouseLeave, onMouseEnter, refChart } =
        useOddButtonWithOddHistory();

    if (!isOddHistoryOpen) {
        return children(innerRef, onMouseEnter, onMouseLeave);
    }

    return (
        <Manager>
            <Reference innerRef={innerRef}>
                {({ ref }) => (
                    <>
                        {children(ref, onMouseEnter, onMouseLeave)}

                        {refChart.current &&
                            createPortal(
                                <Popper placement={placement}>
                                    {({ ref: popperRef, style }) => (
                                        <OddHistory
                                            oddFormat={oddFormat}
                                            oddPath={odd.path}
                                            oddName={odd.name}
                                            fRef={popperRef}
                                            style={style}
                                            marketName={marketName}
                                            sportEvent={sportEvent}
                                        />
                                    )}
                                </Popper>,
                                refChart.current
                            )}
                    </>
                )}
            </Reference>
        </Manager>
    );
};

const OddButtonHistoryWrapper: FC<PropsWrapper> = (props) => {
    const { children, innerRef, isActiveOdd } = props;

    const { oddFormat, showOddHistory } = useReactiveVar(gamblerSettingsVar);

    if (!isActiveOdd || !showOddHistory) {
        return children(innerRef);
    }

    return (
        <OddButtonWithOddHistory
            {...props}
            oddFormat={oddFormat || OddFormat.Decimal}
        >
            {children}
        </OddButtonWithOddHistory>
    );
};

export default memo(OddButtonHistoryWrapper);
