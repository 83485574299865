import { CSSProperties, FC, memo, Ref } from 'react';
import cn from 'classnames';
import { last } from 'lodash';

import { OddFormat } from 'app-constants';
import { MatchBase } from 'types/gql.bet';
import { formatDecimalOdd } from 'utils';
import Chart from './Chart';
import useOddHistory from './useOddHistory';

interface Props {
    oddPath: string;
    oddName: string;
    marketName: string;
    style: CSSProperties;
    fRef: Ref<HTMLDivElement>;
    oddFormat: OddFormat;
    sportEvent?: MatchBase;
}

const OddHistory: FC<Props> = ({
    oddPath,
    oddName,
    style,
    fRef,
    marketName,
    oddFormat,
    sportEvent,
}) => {
    const { dynamics } = useOddHistory({
        oddPath,
        sportEvent,
    });

    const firstCoef = dynamics[0];
    const secondCoef = last(dynamics);

    if (!firstCoef || !secondCoef) return null;

    const firstValue = formatDecimalOdd(firstCoef, oddFormat);
    const lastValue = formatDecimalOdd(secondCoef, oddFormat);

    const textStyles = cn('relative h-[14px] w-[225px] text-sm');

    return (
        <div
            style={style}
            ref={fRef}
            className="z-max m-2 h-auto w-auto border-surface-dark bg-surface-light shadow-dropdown"
        >
            <div className="flex h-12 flex-col justify-center border-b-default border-b-surface-dark px-2">
                <div className={cn(textStyles, 'mb-2 text-primary-white')}>
                    {marketName}
                </div>
                <div className={cn(textStyles, 'line-clamp-1 text-grey-500')}>
                    {oddName}
                </div>
            </div>
            <div className="flex h-[68px] w-full items-center bg-radial-gradient-b-small from-violet-900 to-violet-700/20 px-2">
                <div className="m-auto h-[20px] min-w-min px-1 text-center text-sm text-grey-300">
                    {firstValue}
                </div>
                <div className="relative h-[80%] w-[145px]">
                    <div className="absolute top-1/2 translate-y-[-50%]">
                        <Chart data={dynamics} />
                    </div>
                </div>
                <div className="m-auto h-[20px] min-w-min px-1 text-center text-sm text-grey-300">
                    {lastValue}
                </div>
            </div>
        </div>
    );
};

export default memo(OddHistory);
