import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useMemo,
} from 'react';

type KycCheckContextType = {
    handleKycCheck: VoidFunction;
};

const KycCheckProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { handleKycCheck } = useKycCheck();

    const value = useMemo(
        () => ({
            handleKycCheck,
        }),
        [handleKycCheck]
    );

    return (
        <KycCheckContext.Provider value={value}>
            {children}
        </KycCheckContext.Provider>
    );
};

export const KycCheckContext = createContext<KycCheckContextType>({
    handleKycCheck: () => {},
});

export const useKycCheck = (): KycCheckContextType =>
    useContext(KycCheckContext);

export default KycCheckProvider;
