import React from 'react';

import { CirclePosition, drawChart, Line } from './utils';

interface Props {
    data: number[];
}

const svgConfig = {
    width: 145,
    padding: 15,
    border: 2,
    maxHeight: 50,
};

const Chart: React.FC<Props> = ({ data }) => {
    const {
        result: { circlePosition, points },
        height,
        colors,
    } = drawChart(data, svgConfig);

    return (
        <svg width={svgConfig.width} height={height}>
            <defs>
                <linearGradient id="down" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: colors.up }} />
                    <stop offset="100%" style={{ stopColor: colors.down }} />
                </linearGradient>
                <linearGradient id="up" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: colors.down }} />
                    <stop offset="100%" style={{ stopColor: colors.up }} />
                </linearGradient>
                <linearGradient id="first_up" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: colors.first }} />
                    <stop offset="100%" style={{ stopColor: colors.up }} />
                </linearGradient>
                <linearGradient
                    id="first_down"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                >
                    <stop offset="0%" style={{ stopColor: colors.first }} />
                    <stop offset="100%" style={{ stopColor: colors.down }} />
                </linearGradient>
            </defs>
            {points.map((obj: Line) => (
                <line
                    key={obj.x1}
                    x1={obj.x1}
                    y1={obj.y1}
                    x2={obj.x2}
                    y2={obj.y2}
                    fill={obj.gradient}
                    stroke={obj.gradient}
                    strokeWidth={svgConfig.border}
                />
            ))}
            {circlePosition.map((obj: CirclePosition) => (
                <circle
                    key={obj.x}
                    cx={obj.x}
                    cy={obj.y}
                    r={svgConfig.border}
                    fill={obj.color}
                    stroke={obj.color}
                    strokeWidth={svgConfig.border}
                />
            ))}
        </svg>
    );
};

export default React.memo(Chart);
